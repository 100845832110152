import store from '@/stores/notifications'

export default (flash) => {
  if (flash) {
    Object.keys(flash).forEach(level => {
      const text = flash[level]

      if (!text) {
        return
      }

      if (typeof text === 'string') {
        store.addNotification({ level, text })
      }

      if (typeof text === 'object') {
        store.addNotification({
          ...text,
          level,
        })
      }
    })
  }
}
