import '../css/app.css'
import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import flash from '@/plugins/flashesNotifications'
import get from 'just-safe-get'
import plugins from '@/plugins/index.js'
import components from '@/components/index.js'
import * as Sentry  from '@sentry/vue'
const setUser = user => {
  Sentry.setUser({
    id: user.id,
    username: user.name,
    email: user.email,
    ip_address: '{{auto}}',
  })
}

router.on('success', (event) => {
  if (get(event, 'detail.page.props.user.id') && import.meta.env.PROD) {
    setUser(event.detail.page.props.user)
  }
})

createInertiaApp({
  resolve: (name) => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const vueApp = createApp({ render: () => h(App, props) })
      .use(plugin)

    components(vueApp)
    plugins.forEach(vueApp.use)

    if (import.meta.env.PROD) {
      Sentry.init({
        app: vueApp,
        dsn: "https://a0988d99b2926bacf0f25d359cebd949@o4507314961448960.ingest.us.sentry.io/4507314963415040",
        integrations: [],
        tracesSampleRate: 1.0,
        maxBreadcrumbs: 50,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: import.meta.env.MODE,
        allowUrls: [/^https:\/\/([\w-]+\.)*lessongenie\.app(\/.*)?$/],
        ignoreErrors: ['Network Error', 'Importing a module script failed'],
      })

      if (get(props, 'initialPage.props.user.id')) {
        setUser(get(props, 'initialPage.props.user'))
      }
    }

    vueApp.mount(el)

    el.removeAttribute('data-page')
    flash(get(props, 'initialPage.props.flash'))
  },
  progress: {
    color: '#E30613',
  },
})
